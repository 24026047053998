import './bootstrap';
import Promise from 'bluebird';
import JsZip from 'jszip';
import FileSaver from 'file-saver';

const download = async url => {
    const response = await fetch(url, {mode: 'no-cors'})
    return await response.blob()
}


const downloadByGroup = (urls) => {
    return Promise.all(
        urls.map(url => download(url.url)),
    );
}

const exportZip = (blobs, urls) => {
    const zip = JsZip();
    blobs.forEach((blob, i) => {
        zip.file(urls[i].name, blob);
    });
    zip.generateAsync({type: 'blob'}).then(zipFile => {
        const currentDate = new Date().getTime();
        const fileName = `lense-photos-${currentDate}.zip`;
        return FileSaver.saveAs(zipFile, fileName);
    });
}

window.onDownloadZip = async function (urls) {

    const downloaded = await downloadByGroup(urls);
    exportZip(downloaded, urls);
}
